<template>
    <div id="app" style="padding: 18px;">
        <header>
            <img src='./assets/devhook-logo-full.png' alt='' style="width: 200px; margin: auto; display: block;">
        </header>
        <main v-if="endpoint">
            <div class="routing row" style="text-align: center; margin-bottom: 24px;">
                <h4 style="margin-bottom: 4px;">Webhook endpoint</h4>
                <p class='exp'>Webhooks arriving to the following URL will be output on this page<span class="tooltip"><span class="tooltip-text">For example, if you want to display GitHub webhook notifications on this page,<br>set the following URL in the webhook URL input field on GitHub's management page.</span></span></p>
                <div class="endpoint-container">
                  <div class="text" id="endpoint">{{ endpoint.url }}</div>
                  <button class="copy-button" id="copyBtn" @click="copyToClipboard">Copy</button>
                  <span v-show="copyMessageVisible" class="copy-message">Copied!</span>
                </div>
                <h4 style="margin-bottom: 4px;">Proxy to<span style="font-weight: normal;font-size: 14px;display: inline-block;margin-left: 2px;">(optional)</span></h4>
                <p class='exp'>Set the destination URL to forward webhook data received on the above URL</p>
                <input type="url" v-model="endpoint.proxy_to" placeholder="http://localhost:1111" class="proxy-input">
            </div>
            <template v-if="endpoint.logs.length > 0">
              <div v-for="log in endpoint.logs" v-bind:key="log.uuid" class="log">
                <div class='log-item'>
                    <div class="datetime">{{ toJST(log.created_at) }}</div>
                    <div class='flex' style="gap: 16px">
                      <div class="">
                          <span style="font-weight: bold; font-size: 14px;">Incoming Header</span>
                          <json-viewer :expand-depth="5"
                                       copyable
                                       boxed
                                       :value="log.header"></json-viewer>
                      </div>
                      <div class="">
                          <span style="font-weight: bold; font-size: 14px;">Incoming Body</span>
                          <json-viewer :expand-depth="5"
                                       copyable
                                       boxed
                                       :value="log.body"></json-viewer>
                      </div>
                    </div>
                </div>

                <div class="actions">
                    <div class="action" v-for="action in log.actions" v-bind:key="action.uuid">
                       <div style="margin-bottom: 10px;">
                           <div>
                               Proxy to
                               <input type="url" v-model="action.proxy_to" style="min-width: 248px; margin-right: 2px;"><button :disabled="!action.proxy_to" @click="resend(action, log)">ReSend</button>
                           </div>
                       </div>
                       <div class='flex' style="gap: 12px">
                         <div>
                             <span style="font-weight: bold; font-size: 14px;">Response Header</span>
                             <json-viewer :expand-depth=5
                                          copyable
                                          boxed
                                          :value="action.header"></json-viewer>
                         </div>
                         <div class='response-body-container'>
                             <div style="font-weight: bold; font-size: 14px;">Response Body</div>
                             <json-viewer :expand-depth=5
                                          copyable
                                          boxed
                                          :value="action.body"></json-viewer>
                         </div>
                       </div>
                    </div>
                </div>
              </div>
            </template>
            <div v-else>
              <p style="margin-bottom: 0; text-align: center">Webhooks will be output here in the following format</p>
              <img src='https://devhook.app/img/sample-output.png' alt='' style="width: 100%; max-width: 800px; display: block; margin: auto;">
            </div>
        </main>
        <div id="contactModal" class="contact-modal" :style="{ opacity: modalVisible ? '1' : '0', pointerEvents: modalVisible ? 'auto' : 'none' }">
          <div class="modal-inner">
            <button id="closeContactModal" class="close-btn" @click="toggleModal">
              <svg class="close-icon" xmlns="http://www.w3.org/2000/svg"  fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
            <h2 class='title'>Contact</h2>
            <div class='form-wrap'>
              <form method='post' action='https://hyperform.jp/api/2lS8tfZI'>
                <div class="email-wrap">
                  <label for="email" class="email-label">email</label>
                  <input type="email" id="email" name="email" required pattern=".+\.[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]" class="email-input">
                </div>
                <div class="content-wrap">
                  <label for="message" class="content-label">content</label>
                  <textarea id="message" name="お問い合わせ内容" required class="content-textarea"></textarea>
                </div>
                <div class="privacy-wrap">
                  <input type='checkbox' name='privacy-consent' id='privacy-consent' checked required>
                  <label for="privacy-consent" class="privacy-label">Agree to the <a class='privacy-link' href='https://devhook.app/privacy' target='_blank' rel='noopener'>privacy policy</a></label>
                </div>
                <button class="submit-btn" type='submit'>Submit</button>
              </form>
            </div>
          </div>
          <p class='copy-right'>© Copyright donemika Inc.</p>
        </div>
        <button id="openContactModal" class="open-btn" @click="toggleModal" :style="{ opacity: modalVisible ? '0' : '1', pointerEvents: modalVisible ? 'none' : 'auto' }">
          <svg xmlns="http://www.w3.org/2000/svg" class='chat-mark' viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M149.7,195.9l-14.8,24.7a8.1,8.1,0,0,1-13.8,0l-14.8-24.7a7.9,7.9,0,0,0-6.8-3.9H40a8,8,0,0,1-8-8V56a8,8,0,0,1,8-8H216a8,8,0,0,1,8,8V184a8,8,0,0,1-8,8H156.5A7.9,7.9,0,0,0,149.7,195.9Z" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></path></svg>
          <span class="contact-text">CONTACT</span>
        </button>
    </div>
</template>

<style lang="scss">
span.jv-button {
  color: #5151A1!important;
}

.log {
  background: #cbd8e9;
  padding: 16px;
  border-radius: 8px;
  margin: 8px 0;
}

.flex {
  display: flex;
}
.flex div {
  width: 100%;
}
.actions {
  margin-left: 40px;
}
.action {
  background: #E6E6FA;
  border-radius: 8px;
  margin: 8px 0;
  padding: 16px;
}

.exp {
  margin: 0 0 4px;
  font-size: 12px;
}

.tooltip {
  content: '';
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 16px;
  position: relative;
  cursor: pointer;
  vertical-align: bottom;
  background-color: #acbcc7;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' fill='%23fff' viewBox='0 0 256 256'%3E%3Crect width='256' height='256' fill='none'%3E%3C/rect%3E%3Ccircle cx='128' cy='128' r='96' fill='none' stroke='%23acbcc7' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/circle%3E%3Ccircle cx='128' cy='180' r='12'%3E%3C/circle%3E%3Cpath d='M128,144v-8a28,28,0,1,0-28-28' fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='16'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 4px;
  &:hover .tooltip-text {
    opacity: 1;
    visibility: visible;
  }
}
.tooltip-text {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -35px;
  display: inline-block;
  padding: 8px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.3;
  background: #333;
  color: #fff;
  border-radius: 3px;
  transition: 0.3s ease-in;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  cursor: default;
  a {
    color: #79d2f0;
    text-decoration: underline;
    cursor: pointer;
  }
}

.endpoint-container {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  justify-content: center;
  .text {
    padding: 8px;
    border: 1.5px solid #d4cbcb;
    border-radius: 5px;
    margin-right: 10px;
    background: #ededed;
    font-size: 14px;
  }
  .copy-button {
    background-color: #5151A1;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
}

.proxy-input {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1.5px solid #ced4da;
  min-width: 300px;
  &:focus {
    border: 2px solid #5151a176;
    outline: 0;
  }
}

.copy-message {
  display: inline-block;
  margin-left: 10px;
  color: #5151A1;
  font-size: 14px;
}

.log-item {
  .datetime {
    font-size: 14px;
    margin-bottom: 8px;
  }
}
.jv-container .jv-tooltip.right {
  right: 0;
  .jv-button {
    position: absolute;
    right: 10px;
    top: 4px;
  }
}

.response-body-container {
  .jv-code.boxed {
    width: 90%;
  }
}

.contact-modal {
  position: fixed;
  right: 0.5rem;
  bottom: 1rem;
  background-color: #ffffff;
  transition-property: all;
  width: 91.666667%;
  pointer-events: none;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  opacity: 0;
  max-width: 360px;
  min-height: 475px;
  box-sizing: border-box!important;
  font-size: 14px;
  .modal-inner {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #ffffff;
    width: 100%;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    height: 150px; background: linear-gradient(135deg, #5151A1 0%, #559ce1 100%)
  }
  .close-btn {
    position: absolute;
    top: 0.25rem;
    right: 1.75rem;
    padding: 0.25rem;
    margin-right: -1.5rem;
    transition-property: all;
    color: #C7D2FE;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .close-btn:hover {
    background-color: #3B82F6;
    color: #ffffff;
  }
  .close-icon{
    width: 100%;
    height: 100%;
  }
  .title {
    margin-bottom: 0.25rem;
    margin-top: 4px;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: normal;
  }

  .mordal-exp {
    margin-bottom: -0.5rem;
    color: #D1D5DB;
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-top: 8px;
  }

  .form-wrap {
    position: absolute;
    right: 50%;
    padding: 1rem;
    background-color: #ffffff;
    width: calc(100% - 60px);
    border-radius: 0.25rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    top: 75px;
    transform: translateX(50%);
  }

  .email-wrap {
    position: relative;
    margin-bottom: 1rem;
  }

  label.email-label {
    display: block;
    margin-bottom: 0.25rem;
    color: #4B5563;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 1.75rem;
  }

  input.email-input {
    padding: .25rem .75rem;
    background-color: #ffffff;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #374151;
    font-size: 1rem;
    line-height: 1.5rem;
    line-height: 2rem;
    width: 100%;
    border-radius: 0.25rem;
    outline: 0;
    border: solid 1px #D1D5DB;
    box-sizing: border-box;
  }

  div.content-wrap {
    position: relative;
    margin-bottom: 0.25rem;
  }

  label.content-label {
    display: block;
    margin-bottom: 0.25rem;
    color: #4B5563;
    font-size: 0.875rem;
    line-height: 1.25rem;
    line-height: 1.75rem;
  }

  .content-textarea {
    padding: .25rem .75rem;
    background-color: #ffffff;
    transition-property: background-color, border-color, color, fill, stroke;
    transition-duration: 200ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    color: #374151;
    font-size: 1rem;
    line-height: 1.5rem;
    line-height: 1.5rem;
    width: 100%;
    height: 8rem;
    border-radius: 0.25rem;
    border-width: 1px;
    border-color: #D1D5DB;
    resize: none;
    outline: none;
    box-sizing: border-box;
  }

  .privacy-wrap {
    position: relative;
    margin-bottom: 1rem;
  }

  .privacy-label {
    padding-left: 0;
    margin-bottom: 0.25rem;
    color: #4B5563;
    font-size: 0.75rem;
    line-height: 24px;
    cursor: pointer;
    vertical-align: bottom;
  }

  .privacy-link {
    color: #6B7280;
  }

  .submit-btn {
    display: block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #5151A1;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.5rem;
    width: 100%;
    border-radius: 0.25rem;
    border-width: 0;
    width: 120px;
    padding-top: 6px;
    padding-bottom: 6px;
    cursor: pointer;
    margin: auto;
  }

  .submit-btn:hover {
    opacity: .8;
  }

  .copy-right {
    position: absolute;
    bottom: -6px;
    right: 12px;
    color: #6B7280;
    font-size: 10px
  }
}

.open-btn {
  position: fixed;
  right: 0.5rem;
  bottom: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #5151A1;
  transition-property: all;
  color: #ffffff;
  border-radius: 0.5rem;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  outline: none;
  border: none;
}

.open-btn:hover {
  opacity: .8;
}

.chat-mark {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 6px;
}

.contact-text {
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.05em;
  vertical-align: super;
}
</style>

<script>
import axios from 'axios'
import JsonViewer from 'vue-json-viewer'
import dayjs from "dayjs";
import utc from "dayjs-plugin-utc";
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retryDelay: (retryCount) => {
    return retryCount * 1000;
}});
dayjs.extend(utc);

const WATCH_MODE = 'COMET'

export default {
    name: 'App',

    data(){
        return {
            id:'',
            endpoint:null,
            copyMessageVisible: false,
            modalVisible: false,
        }
    },
    methods:{
        toggleModal() {
          this.modalVisible = !this.modalVisible;
        },
        async resend(action, log) {
            const res = await axios.post(action.proxy_to,log.body,{
                headers:log.headers,
            })
            log.actions.unshift({
                headers:res.headers,
                body:res.data,
                proxy_to:this.endpoint.proxy_to
            })
        },
        async watch(){
            if (WATCH_MODE === "COMET") {
                const watcher = async () => {
                    const res = await axios.post('/api/endpoint/'+this.id+'/watch', {
                        updated_at:this.endpoint.updated_at
                    })
                    this.endpoint.updated_at = res.data.updated_at
                    res.data.logs.map(async (log) => {
                        if(this.endpoint.logs.find(l => l.id === log.id)) {
                            return
                        }
                        this.endpoint.logs.unshift(log)
                        if (log.actions.length == 0 && this.endpoint.proxy_to) {
                            const res = await axios.post(this.endpoint.proxy_to,log.body,{
                                headers:log.headers,
                            })
                            log.actions.push({
                                headers:res.headers,
                                body:res.data,
                                proxy_to:this.endpoint.proxy_to
                            })
                            await axios.post('/api/response/'+log.id, {
                                response_header:res.headers,
                                response_body:res.data
                            })
                        }
                    })
                    await watcher()
                }
                await watcher()
            }
        },
        copyToClipboard() {
          const copyText = document.getElementById('endpoint').innerText;
          const textArea = document.createElement('textarea');
          textArea.textContent = copyText;
          document.body.appendChild(textArea);
          textArea.select();
          document.execCommand('copy');
          document.body.removeChild(textArea);
          this.copyMessageVisible = true;
            setTimeout(() => {
              this.copyMessageVisible = false;
          }, 2000);
        },
        toJST(dateString) {
          const jstOffset = 9 * 60; // 日本時間は UTC+9
          return dayjs.utc(dateString).utcOffset(jstOffset).format("YYYY-MM-DD HH:mm");
        },
    },
    components: {
        JsonViewer
        },
    async mounted() {
        this.id = location.hash.substring(1)
        if (!this.id) {
            const res = await axios.post('/api/endpoint')
            this.id = res.data.id
            location.hash = this.id
        }

        const res = await axios.get('/api/endpoint/'+this.id)
        this.endpoint = res.data
        console.log(this.endpoint)
        setTimeout(() => {
            this.watch()
        })
    }
}
</script>
